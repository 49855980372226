var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Data, Discount, Member, Offer, Order, OrderManager, Spot, Store } from 'aigens-ng-core';
import { ConfigService } from './config.service';
import { AQuery } from '../base/aquery';
import { BaseService } from '../base/base-service';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MemberService } from './member.service';
import { TranslateService } from '@ngx-translate/core';
import { version } from '../../../../package.json';
import { ActivatedRoute } from '@angular/router';
import { CartService } from './cart.service';
import { SdkConfigService } from './sdk-config-service';
import { UUID } from 'angular2-uuid';
import { SystemConfigService } from './systemConfig.service';
import { AddressManagerService } from './address-manager.service';
import { getCountryCallingCode } from 'src/app/shared/utilities/checkFormatTool';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./cart.service";
import * as i3 from "aigens-ng-core/dist/manager/order-manager";
import * as i4 from "@angular/router";
import * as i5 from "./member.service";
import * as i6 from "./config.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./sdk-config-service";
import * as i9 from "./systemConfig.service";
import * as i10 from "./address-manager.service";
var OrderService = /** @class */ (function (_super) {
    __extends(OrderService, _super);
    function OrderService(http, cartService, orderManager, route, memberService, configs, translate, sdkConfigService, systemConfigService, addressManager) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.cartService = cartService;
        _this.orderManager = orderManager;
        _this.route = route;
        _this.memberService = memberService;
        _this.configs = configs;
        _this.translate = translate;
        _this.sdkConfigService = sdkConfigService;
        _this.systemConfigService = systemConfigService;
        _this.addressManager = addressManager;
        _this.address = {};
        _this.currency = 'HKD';
        _this.selectedDineinTakeoutPrompt = 'firstView';
        _this.sign = '$';
        /**
         *  mode option:
         *  read from url
         *  dinein
         *  takeaway
         *  prekiosk
         *  preorder
         *  if pickup: popup alert to select dinein/takeaway
         *  if no,
         *  byod
         *
         *
         * */
        _this.selfPickUp = false;
        _this.delivery = false;
        _this.takeout = false;
        _this.isBK = false;
        _this.store$ = new BehaviorSubject(null);
        _this.hiddenNav = false;
        _this.hideTotal = false;
        _this.comeInHasSession = false;
        _this.hasServiceChargeOrTax = false;
        _this.isCourt = false;
        _this.preOrder = false;
        _this.login = false;
        _this.isStaff = false;
        _this.isHomeButton = false;
        _this.settings = {};
        _this.promoCodeGift = [];
        _this.seatsGift = [];
        _this.sourceGift = [];
        _this.gaTrack = {
            'cate-to-grid-level': 1,
            'grid-to-cart-level': 1
        };
        _this.seats = null;
        _this.phoneNotify = true;
        _this.emailReceipt = true;
        // coupon map
        _this.couponMap = {
            'coupon': null,
            'couponDisplay': null,
            'couponBenefit': null,
            'couponConds': [],
            'canShowCouponInput': true,
            'useCoupon': false,
            'couponMenuDiscounts': null,
            'canUseCoupon': false,
        };
        _this.name = null;
        _this.generalGift = [];
        _this.marketing1 = true;
        _this.marketing2 = true;
        _this.aq = new AQuery(http, configs);
        return _this;
    }
    Object.defineProperty(OrderService.prototype, "hasCrm", {
        get: function () {
            var crm = (this.orderManager.store && this.orderManager.store.brand && this.orderManager.store.brand.crmId) || null;
            return !(!crm);
        },
        enumerable: true,
        configurable: true
    });
    OrderService.prototype.setStore = function (store) {
        this.store = store;
        this.setCurrency(store.currency);
        var tmpSettings = store.menu && store.menu.settings ? store.menu.settings : null;
        if (tmpSettings) {
            this.settings[store.id] = tmpSettings;
        }
        this.hasServiceChargeOrTax = true; // store.menu && store.menu.settings ? (store.menu.settings.serviceCharge > 0 || store.menu.settings.tax > 0) : false;
        this.chargeDesc = store.menu && store.menu.settings ? store.menu.settings.chargeDesc : '';
        // if (store.hasFeature('kiosk') && !this.spot && !this.preOrder) {
        //     this.orderManager.mode = 'prekiosk';
        // } else if (store.hasFeature('byod')) {
        //     this.orderManager.mode = 'byod';
        // }
    };
    // set store BehaviorSubject
    OrderService.prototype.setStore_BehaviorSubject = function (store) {
        this.store$.next(store);
    };
    OrderService.prototype.setCurrency = function (currency) {
        if ('HKD' === currency) {
            this.sign = '$';
        }
        else if ('CNY' === currency) {
            this.sign = '¥';
        }
        else if ('SGD' === currency) {
            this.sign = 'S$';
        }
        else if ('MYR' === currency || 'RM' === currency) {
            this.sign = 'RM';
        }
        else if ('IDR' === currency) {
            this.sign = 'Rp';
        }
        else if (!currency) {
            currency = 'HKD';
            this.sign = '$';
        }
        this.currency = currency;
        if (this.store && this.store.currencySign) {
            this.sign = this.store.currencySign;
        }
    };
    OrderService.prototype.getCurrentStoreSetting = function () {
        if (!this.store) {
            return null;
        }
        return this.settings[this.store.id];
    };
    OrderService.prototype.clear = function () {
        this.pickUpLabel = null;
        this.pickUpTime = null;
        this.maxPickupTime = null;
        this.selectedReward = null;
    };
    OrderService.prototype.clearCoupon = function () {
        this.couponMap = {
            'coupon': null,
            'couponDisplay': null,
            'couponBenefit': null,
            'couponConds': [],
            'canShowCouponInput': true,
            'useCoupon': false,
            'couponMenuDiscounts': null,
            'canUseCoupon': false,
        };
    };
    OrderService.prototype.clearPromoCodeGift = function () {
        this.promoCodeGift = [];
    };
    OrderService.prototype.postOrder = function (order) {
        var href = window.location.href || '';
        if (href.indexOf('/prekiosk') > 0 && this.store.hasFeature('kiosk')) {
            return this.postPreKioskOrder(order);
        }
        // if (this.orderManager.mode === 'prekiosk') {
        //     return this.postPreKioskOrder(order);
        // }
        if (this.orderManager.mode === 'byod') {
            return this.postByodOrder(order);
        }
    };
    OrderService.prototype.postMultiOrder = function (orders, token) {
        var url = '/api/v1/menu/orders.json?type=byod';
        console.log('orders', orders);
        var body = {};
        var headers = {};
        // orders.forEach((order) => {
        //     order.takeout = "takeaway" === this.orderManager.mode;
        // })
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);
        console.log('body', body);
        for (var _i = 0, _a = body['orders']; _i < _a.length; _i++) {
            var order = _a[_i];
            order['type'] = 'byod';
            order['session'] = this.session; // "1111";
            order['deviceId'] = this.session;
            order['takeout'] = 'takeaway' === this.orderManager.mode;
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        body['token'] = token;
        body['payment'] = 'stripe';
        body['email'] = this.email;
        if (!this.pickUpTime || this.pickUpTime === '0') {
            body['pickupTime'] = null;
        }
        else {
            body['pickupTime'] = Number(this.pickUpTime);
        }
        if (this.phone) {
            body['phone'] = this.phone;
        }
        console.log('body', body);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Order, jo['data']); }));
    };
    OrderService.prototype.postPreKioskOrder = function (order) {
        var url = '/api/v1/menu/order.json';
        // order.takeout = "takeaway" === this.orderManager.mode;
        var body = Order.toOrderData(order); // this.slimOrder(order);
        body['type'] = 'prekiosk';
        body['session'] = this.session;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        console.log('body', body);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Order, jo['data']); }));
    };
    OrderService.prototype.postByodOrder = function (order) {
        var url = '/api/v1/menu/order.json';
        // order.takeout = "takeaway" === this.orderManager.mode;
        var body = Order.toOrderData(order); // this.slimOrder(order);
        var headers = {};
        var member = this.memberService.getMember();
        if (this.cartService.enable) {
            var t = {
                uuid: this.memberService.uuid,
                name: this.memberService.name,
                lockTime: Date.now()
            };
            body['lock'] = t;
        }
        body['type'] = 'byod';
        body['session'] = this.session;
        body['spot'] = this.spot;
        body['deviceId'] = this.deviceId;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['pickUpTime'] = this.pickUpTime;
        if (this.passcode) {
            body['passcode'] = this.passcode;
        }
        if (this.systemConfigService.isBatchOrder) {
            body['batchId'] = UUID.UUID().toString();
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        console.log('body', body);
        var aq = this.aq;
        if (this.orderManager.store && this.orderManager.store.country) {
            aq.params = { country: this.orderManager.store.country };
        }
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Order, jo['data']); }));
    };
    OrderService.prototype.postOrderWithPaymentObject = function (order, paymentObject) {
        var url = '/api/v1/menu/order.json';
        var groupId = this.configs.getGroupId();
        if (Number(groupId) === 50000) {
            // extra handling for CDC
            url = '/api/v1/cdc/order.json';
        }
        var body = {};
        // order.takeout = "takeaway" === this.orderManager.mode;
        body = Order.toOrderData(order); // this.slimOrder(order);
        console.log('body', body);
        body['type'] = this.orderManager.mode;
        body['session'] = this.session; // "1111";
        body['storeId'] = order.storeId;
        body['deviceId'] = this.session;
        body['takeout'] = order.takeout;
        if ('delivery' === this.orderManager.mode && this.addressManager.currentAddress && this.addressManager.currentCourier) {
            body['addressId'] = this.addressManager.currentAddress['id'];
            body['courierId'] = this.addressManager.currentCourier['id'];
            body['note'] = this.addressManager.note;
        }
        body['uuid'] = this.uuid;
        body['userToken'] = this.configs.getUserToken();
        console.log('JJ: OrderService -> postOrderWithPaymentObject -> this.sdkConfigService.isCrystalJade', this.sdkConfigService.getMemberCardNo);
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        if (order.id) {
            // for the case which need to reuse the order.id: apply discountCode and pay.
            body['id'] = order.id;
        }
        // TODO: should use order.member
        var member = this.memberService.getMember();
        // if (this.isCourt || member) {
        //     body["member"] = this.member;
        //     if (member) {
        //         headers["sid"] = member.sessionId;
        //     }
        // }
        if (this.isCourt) {
            body['member'] = member;
        }
        else {
            if (member && this.hasCrm) {
                body['member'] = Member.toMemberData(member);
            }
        }
        if (body['member']) {
            if (this.name) {
                body['member']['lastName'] = this.name;
            }
        }
        else {
            if (this.name) {
                var m = {
                    'lastName': this.name
                };
                body['member'] = m;
            }
        }
        if (this.email && this.email !== '') {
            body['email'] = this.email;
        }
        else {
            // body['email'] = 'aigensemail@gmail.com';
        }
        if (this.pickUpTime) {
            body['pickupTime'] = this.pickUpTime;
        }
        if (this.countryCode) {
            body['countryCode'] = '+' + this.countryCode;
        }
        else if (this.store.country) {
            body['countryCode'] = '+' + getCountryCallingCode(this.store.country);
        }
        if (this.phone) {
            body['phone'] = this.phone;
        }
        if (this.spot) {
            body['spot'] = this.spot;
        }
        body['charge'] = paymentObject;
        body['version'] = version;
        if (this.couponMap['coupon'] && this.couponMap['useCoupon'] && this.couponMap['canUseCoupon']) {
            body['coupon'] = this.couponMap['coupon'];
        }
        if (this.courtId) {
            body['courtId'] = Number(this.courtId);
        }
        if (this.marketing2) {
            body['marketing'] = true;
        }
        body['trackerUrl'] = window.location.origin + '/order/@orderId';
        var aq = this.aq;
        if (this.orderManager.store && this.orderManager.store.country) {
            aq.params = { country: this.orderManager.store.country };
        }
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.auth(true).getJson();
    };
    OrderService.prototype.getBKUserInfo = function (sid) {
        var host = window.location.host;
        var isPrd = host === 'bksg.order.place';
        var server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        var url = server + '/api/v1/bk/member.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        return aq.auth(true).getJson().pipe(map(function (jo) { return Data.toData(Member, jo['data']); }));
    };
    OrderService.prototype.postBKOrder = function (order, paymentObject, isNetspay) {
        if (isNetspay === void 0) { isNetspay = false; }
        var host = window.location.host;
        var isPrd = host === 'bksg.order.place';
        var server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        if (isNetspay) {
            server = isPrd ? 'https://bksgapp.appspot.com' : 'https://nets-dot-bksgtest.appspot.com'; // test nets pay
        }
        var url = server + '/api/v1/bk/order.json?menu=' + this.orderManager.mode;
        // order.takeout = "takeaway" === this.orderManager.mode;
        var body = Order.toOrderData(order); // this.slimOrder(order);
        // body["storeId"] = 5741421214564352;
        // body["type"] = "delivery";
        body['station'] = 'mobile';
        body['test'] = !isPrd; // when prd it should be false
        body['note'] = this.address['comment'];
        body['charge'] = paymentObject;
        // body["payment"] = "stripe";
        body['session'] = this.session;
        body['delivery'] = 'delivery' === this.orderManager.mode;
        // body["takeout"] = false;
        body['takeout'] = 'takeaway' === this.orderManager.mode;
        body['email'] = this.email.toLowerCase();
        if (this.pickUpTime && this.orderManager.mode === 'delivery') {
            body['deliveryTime'] = this.pickUpTime;
        }
        else if (this.pickUpTime && this.orderManager.mode !== 'delivery') {
            body['pickupTime'] = this.pickUpTime;
        }
        if (this.firstName && this.lastName) {
            body['firstName'] = this.firstName;
            body['lastName'] = this.lastName;
        }
        if (this.phone) {
            body['phone'] = this.phone;
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        this.address['city'] = 'Singapore';
        this.address['country'] = 'SG';
        body['address'] = this.address;
        console.log('body', body);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Order, jo['data']); }));
    };
    OrderService.prototype.getBKReward = function () {
        var host = window.location.host;
        var isPrd = host === 'bksg.order.place';
        var server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        var url = server + '/api/v1/bk/reward/offer.json';
        var params = {};
        params['sid'] = this.session;
        console.log('params', params);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Offer, jo['data']); }));
    };
    OrderService.prototype.getReward = function (brandId) {
        if (brandId === void 0) { brandId = null; }
        var bId = brandId ? brandId : this.store.brandId;
        var url = '/api/v1/menu/offer.json?brandId=' + bId;
        if (this.store) {
            url = url + '&storeId=' + this.storeId;
        }
        var member = this.memberService.getMember();
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Offer, jo['data']); }));
    };
    OrderService.prototype.getCourtReward = function () {
        var url = '/api/v1/menu/offer.json?courtId=' + this.courtId;
        var member = this.memberService.getMember();
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        if (member) {
            aq.auth(true);
        }
        return aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Offer, jo['data']); }));
    };
    OrderService.prototype.checkOTP = function (passcode) {
        var url = '/api/v1/menu/spot.json'; // "/api/v1/store/otpverify.json?storeId=" + this.storeId +"&passcode=" + passcode;
        var params = {};
        params['action'] = 'verify';
        params['name'] = this.spot;
        params['storeId'] = this.storeId;
        params['passcode'] = passcode;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo['vefified']; }));
    };
    OrderService.prototype.getSpots = function (storeId) {
        var url = '/api/v1/menu/spot.json';
        var params = {};
        params['storeId'] = storeId;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Spot, jo['data']); }));
    };
    OrderService.prototype.checkSpot = function (storeId, spot, deviceId, code) {
        var url = '/api/v1/menu/spot.json';
        var params = {};
        params['storeId'] = storeId;
        params['spot'] = spot;
        params['deviceId'] = deviceId;
        if (code) {
            params['code'] = code;
        }
        params['check'] = true;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson();
    };
    OrderService.prototype.getByodOrders = function (storeId, spot, deviceId) {
        var url = '/api/v1/menu/order.json';
        var params = {};
        if ('delivery' !== this.orderManager.mode) {
            params['session'] = this.session;
        }
        else {
            url = '/api/v1/menu/history.json';
        }
        if (spot) {
            params['spot'] = spot;
            if (deviceId) {
                params['deviceId'] = deviceId;
            }
        }
        params['type'] = this.orderManager.mode;
        params['storeId'] = storeId;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Order, jo['data']); }));
    };
    OrderService.prototype.getOrders = function (session, partial, limitTo, limitFrom, since) {
        if (partial === void 0) { partial = false; }
        if (limitTo === void 0) { limitTo = 10; }
        if (limitFrom === void 0) { limitFrom = 0; }
        if (since === void 0) { since = null; }
        var url = '/api/v1/menu/order.json';
        var params = {};
        if (this.isCourt) {
            params['type'] = 'court';
            params['courtId'] = this.courtId;
        }
        params['session'] = session;
        params['fields'] = 'store,brand,location';
        params['locale'] = this.translate.currentLang;
        if (partial) {
            params['since'] = since;
            params['limitFrom'] = limitFrom;
            params['limitTo'] = limitTo;
        }
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Order, jo['data']); }));
    };
    OrderService.prototype.getBKOrders = function (session) {
        var host = window.location.host;
        var isPrd = host === 'bksg.order.place';
        var server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        var url = server + '/api/v1/menu/order.json';
        var params = {};
        params['session'] = session;
        // params['fields'] = "store,brand";
        params['locale'] = this.translate.currentLang;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.auth(true).getJson().pipe(map(function (jo) { return Data.toDataArray(Order, jo['data']); }));
    };
    OrderService.prototype.getOrderBySession = function (id, session) {
        if (session === void 0) { session = null; }
        var url = '/api/v1/menu/order/' + id + '.json';
        var params = {};
        params['session'] = session;
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.getJson().pipe(map(function (jo) { return Data.toData(Order, jo['data']); }));
    };
    OrderService.prototype.getOrdersBySession = function (courtId, session, ignoreType) {
        if (session === void 0) { session = null; }
        if (ignoreType === void 0) { ignoreType = false; }
        var url = '/api/v1/menu/order.json';
        var params = {};
        params['session'] = session;
        params['courtId'] = courtId;
        if (!ignoreType)
            params['type'] = 'court';
        this.aq.url = url;
        this.aq.method = 'get';
        this.aq.params = params;
        return this.aq.getJson().pipe(map(function (jo) { return Data.toDataArray(Order, jo['data']); }));
    };
    OrderService.prototype.getBKStore = function (postal) {
        var _this = this;
        var host = window.location.host;
        var isPrd = host === 'bksg.order.place';
        var server = isPrd ? 'https://bksgapp.appspot.com' : 'https://bksgtest.appspot.com';
        var url = server + '/api/v1/bk/store.json?postal=' + postal;
        var aq = this.aq;
        aq.url = url;
        return aq.getJson().pipe(map(function (jo) {
            _this.address.latitude = jo['data']['latitude'];
            _this.address.longitude = jo['data']['longitude'];
            return Data.toDataArray(Store, jo['data']['stores']);
        }));
    };
    OrderService.prototype.postMultiOrderWithPaymentObject = function (orders, paymentObject, isCourt) {
        if (isCourt === void 0) { isCourt = false; }
        // var url = "https://payment-dot-aigensstoretest.appspot.com/api/v1/menu/orders.json";
        var url;
        var mode = (this.isCourt) ? 'court' : this.orderManager.mode;
        if (this.memberService.getMember() && this.memberService.crmId) {
            url = '/api/v1/menu/orders.json?type=' + mode + '&courtId=' + this.courtId + '&sid=' + this.memberService.crmId;
        }
        else {
            url = '/api/v1/menu/orders.json?type=' + mode + '&courtId=' + this.courtId;
        }
        // if ( this.memberService.getMember() && this.memberService.crmId){
        //     url = '/api/v1/menu/orders.json?type=' + this.orderManager.mode  + '&courtId=' + this.courtId + '&sid=' + this.memberService.crmId;
        // } else {
        //     url = '/api/v1/menu/orders.json?type=' + this.orderManager.mode  + '&courtId=' + this.courtId;
        // }
        var body = {};
        var headers = {};
        // orders.forEach((order) => {
        //     order.takeout = "takeaway" === this.orderManager.mode;
        // })
        body['orders'] = Order.toOrdersData(orders); // this.slimMultiOrder(orders);
        if (this.isCourt) {
            body['deviceId'] = this.deviceId;
        }
        for (var _i = 0, _a = body['orders']; _i < _a.length; _i++) {
            var order = _a[_i];
            order['type'] = this.orderManager.mode;
            order['session'] = this.session; // "1111";
            order['deviceId'] = this.session;
            order['takeout'] = 'takeaway' === this.orderManager.mode;
            if (this.courtBrand && this.courtBrand.crmId) {
                order['crmId'] = this.courtBrand.crmId;
            }
            if (this.isCourt) {
                order['member'] = this.memberService.member;
            }
            if (this.spot) {
                order['spot'] = this.spot;
            }
            if ('delivery' === this.orderManager.mode && this.addressManager.currentAddress) {
                order['addressId'] = this.addressManager.currentAddress['id'];
            }
            if (order['member']) {
                if (this.name) {
                    order['member']['lastName'] = this.name;
                }
            }
            else {
                if (this.name) {
                    var m = {
                        'lastName': this.name
                    };
                    order['member'] = m;
                }
            }
            if (order['offers']) {
                body['offers'] = order['offers'];
            }
        }
        if (this.email && this.email !== '') {
            body['email'] = this.email;
        }
        var usedPoints = orders && orders[0] && orders[0].usedPoints;
        if (usedPoints && usedPoints > 0) {
            body['usedPoints'] = usedPoints;
        }
        // if (!this.pickUpTime || this.pickUpTime === '0') {
        //     body['pickupTime'] = null;
        // } else {
        //     let pickUpTime: number = Number(this.pickUpTime);
        //     if (this.pickUpTime.length < 11) {
        //         pickUpTime = Number(this.pickUpTime) * 1000;
        //     }
        //     body['pickupTime'] = pickUpTime;
        // }
        if (this.pickUpTime) {
            body['pickupTime'] = this.pickUpTime;
        }
        else {
            body['pickupTime'] = null;
        }
        if (this.phone) {
            body['phone'] = this.phone;
        }
        if (this.sdkConfigService.isCrystalJade && this.sdkConfigService.getMemberCardNo) {
            body['card'] = this.sdkConfigService.getMemberCardNo;
        }
        if (orders[0].promoCode) {
            body['promoCodes'] = [orders[0].promoCode];
        }
        body['charge'] = paymentObject;
        body['version'] = version;
        console.log('body', body);
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.body = body;
        return aq.auth(true).getJson();
    };
    OrderService.prototype.orderChangeStatus = function (status, orderId) {
        var url = '/api/v1/menu/order/' + orderId + '.json';
        var params = {};
        params['status'] = status;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return Data.toData(Order, jo['data']); }));
    };
    OrderService.prototype.verify = function (orderId) {
        var url = '/api/v1/bk/order/pickup.json';
        var aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'post';
        // var params = { "orderId": orderId, 'passcode': passcode };
        var params = { 'orderId': orderId };
        aq.params = params;
        return aq.getJson().pipe(map(function (jo) { return jo['data']; }));
    };
    OrderService.prototype.checkingMPGSStatus = function (id, paymentId, action) {
        var url = '/api/v1/pay/detail.json';
        var params = {};
        params['orderId'] = id;
        params['payment'] = paymentId;
        if (action) {
            params['action'] = action;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;
        return aq.getJson();
    };
    OrderService.prototype.checkingOrderStatus = function (id, paymentId, action) {
        var url = '/api/v1/pay/success.json';
        var params = {};
        params['orderId'] = id;
        params['payment'] = paymentId;
        if (action) {
            params['action'] = action;
        }
        var aq = this.aq;
        aq.url = url;
        aq.method = 'get';
        aq.params = params;
        return aq.getJson();
    };
    OrderService.prototype.checkingOrderStatusWithUrl = function (url) {
        var tmpUrl = url ? url : '';
        var aq = this.aq;
        aq.url = tmpUrl;
        aq.method = 'get';
        return aq.getJson();
    };
    // 从v1搬过来的code
    OrderService.prototype.editHeadCount = function () {
        var url = '/api/v1/menu/spot.json';
        var params = {};
        params['name'] = this.spot;
        params['seats'] = this.seats;
        params['key'] = this.session;
        params['action'] = 'edit';
        params['storeId'] = this.store.id;
        var aq = this.aq;
        aq.url = url;
        aq.method = 'put';
        aq.params = params;
        return this.aq.auth(true).getJson().map(function (jo) { return Data.toDataArray(Order, jo['data']); });
    };
    OrderService.prototype.postCouponCode = function (code, brandId, storeId) {
        var url = '/api/v1/reward/validatecoupon.json';
        var aq = this.aq;
        aq.url = url;
        aq.method = 'post';
        aq.params = {
            'code': code,
            'brandId': brandId,
            'type': 'byod',
            'storeId': storeId
        };
        if (this.courtId) {
            aq.params['courtId'] = this.courtId;
        }
        return aq.getJson().map(function (jo) { return Data.toData(Discount, jo['data']); });
    };
    OrderService.prototype.closeOrder = function (session, orderId) {
        if (this.session && orderId) {
            var url = "/api/v1/menu/close.json?session=" + this.session;
            var body = {};
            body['orderId'] = orderId;
            var aq = this.aq;
            aq.url = url;
            aq.method = 'post';
            aq.body = body;
            return aq.getJson();
        }
    };
    OrderService.prototype.verifyPay = function (chargeId, paymentConfigId, body) {
        var url = "/api/v1/pay/verify.json?payment=" + paymentConfigId + "&chargeId=" + chargeId + "&shouldRedirect=false";
        // url += `&response-base64=${body['response-base64']}&response-signature-algorithm=${body['response-signature-algorithm']}&response-signature-base64=${body['response-signature-base64']}`;
        var aq = this.aq;
        aq.url = url;
        aq.shouldAuth = true;
        aq.method = 'post';
        aq.body = body;
        return aq.getJson().map(function (jo) { return Data.toDataArray(Order, jo['data']); });
    };
    OrderService.ngInjectableDef = i0.defineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.inject(i1.HttpClient), i0.inject(i2.CartService), i0.inject(i3.OrderManager), i0.inject(i4.ActivatedRoute), i0.inject(i5.MemberService), i0.inject(i6.ConfigService), i0.inject(i7.TranslateService), i0.inject(i8.SdkConfigService), i0.inject(i9.SystemConfigService), i0.inject(i10.AddressManagerService)); }, token: OrderService, providedIn: "root" });
    return OrderService;
}(BaseService));
export { OrderService };
