import {Injectable} from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';


import {BaseService} from '../base/base-service';
import {Store} from "aigens-ng-core";
import {FireStoreConfig} from "../../config";
import Firestore = firebase.firestore.Firestore;


@Injectable()
export class FirestoreService extends BaseService {
    get firestore(): firebase.firestore.Firestore {
        return this._firestore;
    }

    user: any;
    app: firebase.app.App;
    private _firestore: Firestore

    constructor() {
        super();
        this.app = firebase.initializeApp(FireStoreConfig.getConfig(),'store')
        this._firestore = this.app.firestore()
    }


    switchContextAuth(store: Store, callback: Function) {

        var statusUrl = store['statusUrl'];
        if (!statusUrl) return;

        this.app = this.getFirebaseApp(statusUrl);

        if (this.authUnsub) {
            this.authUnsub();
        }

        this.authWithToken(this.app, null, callback);

    }

    authMember(member) {

        var token;

        if (member.extra) {
            token = member.extra.firebaseToken;
        }

        var statusUrl = member.statusUrl;

        if (!token || !statusUrl) return;

        this.auth(statusUrl, token, (user) => {
            console.log("auth success", user);
        });


    }

    auth(statusUrl: string, token: string, callback: Function) {

        this.app = this.getFirebaseApp(statusUrl);

        if (this.authUnsub) {
            this.authUnsub();
        }

        this.authWithToken(this.app, token, callback);

    }

    switchContextAnon(content: any, callback: Function) {


        var statusUrl = content['statusUrl'];
        if (!statusUrl) return;

        this.app = this.getFirebaseApp(statusUrl);

        if (this.authUnsub) {
            this.authUnsub();
        }

        this.authWithAnon(this.app, callback);

    }

    switchContext(content: any) {

        var statusUrl = content['statusUrl'];
        if (!statusUrl) return;

        this.app = this.getFirebaseApp(statusUrl);

    }

    private getFirebaseApp(statusUrl: string): firebase.app.App {

        console.log("statusUrl", statusUrl);

        var segs = statusUrl.split('/');

        var projectId = this.findParam(segs, "projects");

        var apiKey: string;
        var url: URL = new URL(statusUrl);

        if (!!url.searchParams) {
            var urlParams = url.searchParams;
            apiKey = urlParams.get("apiKey");
        } else {
            if (statusUrl.indexOf('apiKey=') != -1) {
                var tempKey = statusUrl.split('?');
                let k = tempKey.find(key => key.indexOf('apiKey=') != -1);
                apiKey = k.substring('apiKey='.length);
            }
        }


        var fbConfig = {
            projectId: projectId
        };

        if (apiKey) {
            fbConfig['apiKey'] = apiKey;
        }

        var app: firebase.app.App = null;

        for (let a of firebase.apps) {
            if (a.name == projectId) {
                app = a;
            }
        }

        if (!app) {
            app = firebase.initializeApp(fbConfig, projectId);
        } else {
            console.log("reusing firebase");
        }

        return app;
    }


    getFirestore(): firebase.firestore.Firestore {


        return this.app.firestore();


    }

    authUnsub: any;

    private authWithToken(app: firebase.app.App, token, callback: Function) {


        this.authUnsub = app.auth().onAuthStateChanged(user => {
            if (user) {

                console.log("user logged in", user);

                callback(user);
            } else {

                if (token) {
                    console.log("signing in with token", token);
                    app.auth().signInWithCustomToken(token).catch(function (error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log("Auth Error", errorCode, errorMessage);
                        callback(null);

                    });
                } else {
                    console.log("must signin to firestore first");
                    callback(null);
                }


            }
        })

    }

    private authWithAnon(app: firebase.app.App, callback: Function) {


        this.authUnsub = app.auth().onAuthStateChanged(user => {
            if (user) {

                console.log("user logged in", user);

                callback(user);
            } else {

                console.log("signing in with anon");


                app.auth().signInAnonymously().catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log("Auth Error", errorCode, errorMessage);
                    callback(null);

                });

            }
        })

    }

    signOut() {

        if (this.authUnsub) {
            this.authUnsub();
        }

        if (this.app) {
            this.app.auth().signOut().then(function () {
                // Sign-out successful.
                console.log("signed off successfully");
            }).catch(function (error) {
                // An error happened.
                console.log("signed off error", error);
            });
        }

    }

    getFirestoreDoc(statusUrl: string) {

        var db = this.getFirestore();

        var segs = statusUrl.split('/');

        var kind = this.findParam(segs, "documents");
        var contentId = this.findParam(segs, kind);

        return db.collection(kind).doc(contentId);

    }

    private findParam(paths: string[], name: string) {

        for (var i = 0; i < paths.length; i++) {
            if (paths[i] == name) {
                return paths[i + 1];
            }
        }

        return null;
    }

    listenOrderStatus(orderId: string, callBack: Function) {
        this.getFirestore().collection("Order").doc(orderId).onSnapshot(doc=>{
            console.log("Current data: ", doc.data());
            callBack(doc.data());
        })
    }
}
