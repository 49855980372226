// import { ViewController } from 'ionic-angular';
import {Component, Injector, OnInit} from '@angular/core';
// import { KioskBasePage } from '../../base/kiosk-base-page';
// import { CurrencyService } from '../../services/currency-service';
import {OrderItem, Recommendation, Store} from 'aigens-ng-core';
import {Category} from 'aigens-ng-core';
import {ItemGroup} from 'aigens-ng-core';
import {Item} from 'aigens-ng-core';

// import { LocalStorageHelper } from "../../utility/local-storage-helper";
import {Order} from 'aigens-ng-core';
import {MathUtils} from 'aigens-ng-core';

import {InventoryManager, OrderManager} from 'aigens-ng-core';
import {MobileBasePage} from '../../core/base/mobile-base-page';

import {NavParams} from '@ionic/angular';
import {MobileBaseItemPage} from 'src/app/core/base/mobile-base-item-page';
import {getSetLayout} from "../../shared/utilities/layout-helper";
import {MultiStepComboPage} from "../../routes/item/multi-step-combo/multi-step-combo-page.component";
import {ComboPage} from "../../routes/item/combo-page/combo-page";
import {VariationScrollDialog} from "../variation-scroll/variation-scroll";
import {popDrownAnimation, popUpAnimation} from "../../shared/animations/leaveAnimation";
import {ModifierItemSelectPageModal} from "../../routes/item/modifier-item-select-modal/modifier-item-select-modal";

/**
 * Generated class for the RecommendList page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */

declare var gtag;

@Component({

    selector: 'page-unlock-popup',
    templateUrl: 'unlock-popup.html',
    styleUrls: ['unlock-popup.scss']

})

export class UnlockPopupDialog extends MobileBaseItemPage implements OnInit {

    store: Store;
    recommendList: Category;
    order: Order;

    animateQtyMap = {};
    newTotal = 0;
    addItems: Item[] = []; // all single items in recommendation list
    qtyTag: number[] = [];

    category: any;
    itemgroups: any;
    max: number;
    displayTitle = '';
    recommendations: Recommendation[];

    soldoutMap = {};
    modifiableMap = {};
    requiredModifierMap = {};

    isVariationOpened = false;
    showImage = true;
    isGift = false; // deal with free items
    comboCache = new Map<number, OrderItem>();

    constructor(private injector: Injector, public navParams: NavParams) {
        super(injector);

        this.recommendList = this.navParams.get('recommendList');
        this.store = this.navParams.get('store');
        this.addItems = this.navParams.get('items');
        this.isGift = this.navParams.get('isGift');
        this.max = this.navParams.get('max'); // for unlocks
        this.order = this.orderManager.order;
        this.recommendations = this.navParams.get('recommendations'); // for AI recommendations

        if (this.max) {
            this.displayTitle = this.t.instant('pages.item-select.chooseAtMost', {max: this.max});
        }
        this.init();
    }

    init() {
        if (this.recommendations) {
            this.addItems = [];
            this.recommendations.forEach(rm => {
                this.addItems.push(rm.item);
                this.qtyTag.push(0);
                if (this.showImage && (!rm.item.images || !rm.item.images['default'])) this.showImage = false;
            });

        } else {

            for (let i = 0; i < this.addItems.length; i++) {
                this.qtyTag[i] = 0;
                if (!this.addItems[i].parentId || this.addItems[i].parentId == this.addItems[i].id) {
                    if (this.showImage && (!this.addItems[i].images || !this.addItems[i].images['default'])) this.showImage = false;
                }
            }

            this.category = this.recommendList;
            this.itemgroups = this.recommendList.groups;
        }
        console.log('input category', this.category)
    }


    updateUI() {

    }

    isModifiable(item: Item): boolean {
        if (!this.modifiableMap[item.id]) {
            this.modifiableMap[item.id] = Item.isModifiable(item);
        }
        return this.modifiableMap[item.id];
    }

    checkStoreMaxItem(): boolean {
        const maxItems = this.orderManager.store.menu.maxItems;
        const infinity = maxItems === 0;
        return (maxItems <= this.orderManager.count && !infinity);
    }

    isRequiredModifier(item: Item): boolean {
        if (this.isModifiable(item)) {
            if (!this.requiredModifierMap[item.id]) {
                this.requiredModifierMap[item.id] = item.mgroups.some(mgroup => mgroup.min > 0);
            }
            return this.requiredModifierMap[item.id];
        } else {
            return false;
        }
    }

    isSet(item: Item) {
        return Item.isSet(this.category, item);
    }

    async toComboPage(item: Item, index: number, parentIndex?: number) {
        let page = getSetLayout(this.orderManager.store.menu.setLayout);

        let componentProps = {
            store: this.store,
            category: this.category,
            groups: this.category.groups,
            item: Item.cloneArray(Item, [item])[0],
            handler: this.getNavParams('handler'),
            onHold: true,
            quantityLimit: this.max
        };

        let cssClass = 'modal-largescreen combo-modal-backdrop-dismiss';
        const modal = await this.navToAssignLayoutItems(page === 'MultiStepComboPage' ? MultiStepComboPage : ComboPage, page, componentProps, {
            relativeTo: this.route,  // replaceUrl: true,
            skipLocationChange: false
        }, cssClass);
        modal.onDidDismiss().then(data => {
            console.log(data)
            this.onAddClicked(index, isNaN(parentIndex) ? index : parentIndex, data['data']['orderItem'])
        })
    }

    async toModifierPage(item: Item) {

    }

    private async isVariationParent(item: Item, index: number) {

        const exist: boolean = this.isParent(item);

        if (exist) {

            let items = item.variations.slice(0) || [];

            if (item.id === item.parentId) {
                items.splice(0, 0, item);
            } else {
                items.push(item);
            }

            // sort the same type of combo
            // items = items.sort((a, b) => a.price - b.price);

            // modal to select the cat-Item combo type
            const input = {
                items: items,
                store: this.store,
                category: this.category,
                orderManager: this.orderManager
            };
            console.log('isVariationParent input', input);
            const modal = await this.modalController.create({
                component: VariationScrollDialog,
                componentProps: input,
                showBackdrop: true,
                backdropDismiss: true,
                cssClass: 'modal-largescreen',
                animated: true,
                enterAnimation: popUpAnimation,
                leaveAnimation: popDrownAnimation
            });
            modal.onWillDismiss().then(async data => { // 若有variationParent 则弹出的modal后再跳转
                const selected = <Item>(data['data']);

                if (selected) {
                    console.log('select & add item,', selected);


                    if (this.isSet(selected) || selected.pgroup) {
                        let j: number;
                        for (j = 0; j < this.addItems.length; j++) {
                            if (this.addItems[j].id == selected.id) break;
                        }
                        await this.toComboPage(selected, index, j)
                    } else {

                        let oi = this.addItem(selected);
                        if (this.isRequiredModifier(selected) || (this.isModifiable(selected) && !this.store.menu.quickSingleItem)) {
                            // let cate = this.category;
                            // this.addItem(selected);
                            let params = {store: this.store, orderitem: oi, edit: true, onHold: true};
                            let m = await this.modalController.create({
                                component: ModifierItemSelectPageModal,
                                componentProps: params,
                                showBackdrop: false,
                                enterAnimation: popUpAnimation,
                                leaveAnimation: popDrownAnimation,
                                cssClass: 'modal-largescreen variation-item-modifier-select'
                            });
                            m.onDidDismiss().then((data) => {
                                console.log('ModifierItemSelectPageModal res', data);
                                if (data['data'] === 'confirm') {
                                    // save item to temp list
                                } else {
                                    // this.removeClicked(selected);
                                }

                            });
                            m.present();

                        } else {
                            // this.showToast(this.t.instant('global.item-added'));
                        }

                    }
                }
                this.isVariationOpened = false;
            });
            this.isVariationOpened = true;
            modal.present();
        }

        return exist;
    }

    isParent(item: Item) {

        return !!(item.variations && item.variations.length > 0);

    }

    // private isVariationParent(i: number) {
    //
    //     let item = this.addItems[i];
    //
    //     let exist: boolean = this.isParent(item);
    //
    //     if (exist) {
    //
    //         let items = item.variations.slice(0);
    //
    //         items.push(item);
    //
    //         // this.presentDialog2(VariationDialog, { items: items, store: this.store, takeout: this.order.takeout }, (selected) => {
    //
    //         //     if (selected) {
    //                 let j: number;
    //                 for (j = 0; j < this.addItems.length; j++) {
    //                     if (this.addItems[j].id == selected.id) break;
    //                 }
    //
    //         //         this.onAddClicked(i, j);
    //         //     }
    //         // });
    //     }
    //
    //     return exist;
    // }


    isSoldout(item: Item): boolean {
        // let now = this.getNow();
        return !this.isParent(item) && (this.inventoryManager.isSoldout(item)); // || !this.menuManager.isAvailableCombo(this.store, this.category, item, this.order.takeout, now));
    }


    // i: parent, j:selected
    onAddClicked(i: number, j: number, oi?: OrderItem) {


        this.animateQtyMap['#' + this.addItems[i].id] = false;

        setTimeout(() => {
            this.animateQtyMap['#' + this.addItems[i].id] = true;
        }, 10);

        this.addItems[j].quantity++;
        this.qtyTag[i]++;
        this.newTotal += this.round(this.addItems[j].price);
        if (oi) {
            this.comboCache.set(j, oi);
        }

    }

    checkTotalQty() {
        let qty = 0;
        this.addItems.forEach(item => {
            qty += item.quantity;
        });
        console.log('selected qty:', qty);
        return qty;
    }

    // addClicked(i: number) {
    //
    //     // this.playClick();
    //
    //     if (this.max && this.checkTotalQty() == this.max) {
    //         if (this.max !== 1) return;
    //
    //         // clear other selection if single select
    //         for (let index = 0; index < this.addItems.length; index++) {
    //             while (this.qtyTag[index] > 0) this.minusItem(index);
    //         }
    //     }
    //     let item = this.addItems[i];
    //
    //     if (this.checkTotalQty() == this.max) return;
    //
    //     // deal with group items
    //     if (this.isVariationParent(i)) return;
    //
    //     // soldout
    //     if (this.isSoldout(item)) return;
    //
    //     // single item
    //     this.onAddClicked(i, i);
    //
    //     console.log('add single item#' + i, this.addItems[i].name);
    // }
    async addClicked(i: number) {
        if (this.max && this.checkTotalQty() >= this.max) {
            if (this.max !== 1) return;

            // clear other selection if single select
            for (let index = 0; index < this.addItems.length; index++) {
                while (this.qtyTag[index] > 0) this.minusItem(index);
            }
        }

        console.log('add clicked', i);

        if (this.checkStoreMaxItem()) {
            this.showStoreMaxItemAlert();
            return;
        }
        let item = this.addItems[i];

        const t = await this.isVariationParent(item, i);
        console.log('variation parent', t);

        if (t) {
            return;
        }


        if (this.isSoldout(item)) {
            return;
        }


        if (this.isSet(item)) {
            await this.toComboPage(item, i);

        } else {
            // if cannot skip modifier page
            if (this.isRequiredModifier(item) || (this.isModifiable(item) && !this.store.menu.quickSingleItem)) {
                let cate = this.category;
                let oi = this.addItem(item);
                // let oi = this.orderManager.createOrderItem(cate, cate.groups[0], item);
                let _groups = cate.groups,
                    groups = _groups && _groups.filter(group => {
                        return this.menuManager.isAvailableGroup(this.store, cate, group, this.orderManager.isTakeout(), Date.now(), false);
                    });

                this.onAddClicked(i, i);

                // this.pushByName('ModifierItemSelectPage', {store: this.store, orderitem: oi}, {
                //     relativeTo: this.route,
                //     //  // replaceUrl: true,
                //     skipLocationChange: false
                // });
            } else {
                const oi = this.addItem(item);
                {
                    // if (!this.categories) {
                    //
                    //     const handler = this.getNavParams('handler');
                    //     handler(oi);
                    //     this.orderManager.minusItem(item, true);
                    //     this.navigationService.popPage();
                    //
                    // }
                }
            }
        }
        if (this.orderService.isCourt) {
            this.gaService.eventTrack('item-list-page', 'select item - ' + this.store.name, item.name);
        }
        // this.bounce();
        console.log(this.orderManager.getQuantity(item));
    }

    add(item: Item, group: ItemGroup, oi: OrderItem, isPreselect: boolean = false, callBack?) {
        let groupIndex = item.groups.findIndex(g => {
            // TODO: is compared by id safe?
            return (g !== null && g !== undefined && g.id === group.id);
        }) + 1;

        const isOptional = group.min === 0;
        // if (this.getGroupMode(group) === 'single') {
        //     // special request only can change isSelected
        //     const max = group.max;
        //     let able = true;
        //     if (!isPreselect) {
        //         if (this.isGroupCompleted(group, groupIndex, 1)) {
        //             this.handlingModifier(oi, item, this.itemMap, () => {
        //             }, () => {
        //                 // this.minusClicked(item, group);
        //                 this.removeClicked(item, callBack);
        //             });
        //         } else if (isOptional) {
        //             const realItem = item;
        //             if (realItem.mgroups && realItem.mgroups.length > 0) {
        //                 this.handlingModifier(oi, item, this.itemMap, () => {
        //                     // this.showToast(this.t.instant('global.item-added'));
        //                 }, () => {
        //                     // this.minusClicked(item, group);
        //                     this.removeClicked(item);
        //                 }, true);
        //
        //             }
        //         }
        //
        //     }
        //
        // } else if (this.getGroupMode(group) === 'quantity') {
        //     // special request can change the number
        // }

    }

    addItem(item: Item) {
        // this.orderService.addSingleItem(this.category, this.groups[0], item);

        const oi = this.orderManager.addSingleItem(this.recommendList, this.recommendList.groups[0], item, false);
        this.orderManager.getQuantity(item);
        if (!(this.isRequiredModifier(item) || (this.isModifiable(item) && !this.settings.quickSingleItem))) {
            // this.showToast(this.t.instant('global.item-added'), 600, 'bottom', 'add-item-toast');
        }
        return oi;
    }


    private removeVariation(i: number) {
        let item = this.addItems[i];

        let items = item.variations.slice(0);
        items.push(item);

        this.qtyTag[i] = 0;
        items.forEach(i => {
            for (let j = 0; j < this.addItems.length; j++) {
                if (this.addItems[j].id == i.id) {
                    this.newTotal -= this.round(this.addItems[j].price * this.addItems[j].quantity);
                    this.addItems[j].quantity = 0;
                }
            }
        });
    }

    minusItem(i: number) {

        if (this.isParent(this.addItems[i])) {
            this.removeVariation(i);
        } else {
            this.addItems[i].quantity--;
            this.qtyTag[i]--;
            this.newTotal -= this.round(this.addItems[i].price);
        }
    }

    removeClicked(i: number) {
        if (this.qtyTag[i] <= 0) return;

        // this.playClick();

        console.log('remove clicked');

        this.minusItem(i);
    }

    clearClicked() {

        // this.playClick();

        this.updateUI();

        this.modalController.dismiss('cancel');

    }

    okClicked() {

        // this.playClick();
        if (this.round(this.newTotal) <= 0 && !this.isGift) return;

        /*
        this.presentDialog(ConfirmDialog, this.t.instant("pages.recommend-list.confirmTitle"), null, null, data => {

            if (data == "ok") {

                console.log('Buy clicked');
                let order: Order = this.orderManager.order;

                this.addItems.forEach(item =>{
                    for (let i = 0; i < item.quantity; i++) {
                        let now: number = this.getNow();
                        let group: ItemGroup = this.itemgroups[0];
                        let oi = this.orderManager.addSingleItem(this.category, group, item);
                    }
                });

                this.onOrderChanged(this.orderManager.order);

                this.updateUI();

                this.viewCtrl.dismiss("ok");
            } else {
                console.log('Cancel clicked');
                this.viewCtrl.dismiss(null);
            }
        });
        */

        console.log('Buy clicked');
        let order: Order = this.orderManager.order;


        for (let index = 0; index < this.addItems.length; index++) {

            let item: Item = this.addItems[index];

            for (let i = 0; i < item.quantity; i++) {

                let group: ItemGroup;
                if (this.recommendations) {
                    group = this.recommendations[index].itemgroup;
                    this.category = this.recommendations[index].category;
                } else {
                    group = this.itemgroups[0];
                }
                let oi;
                if (this.comboCache.has(index)) {
                    oi = this.comboCache.get(index);
                    this.orderManager.addOrderItem(oi);
                } else {
                    oi = this.orderManager.addSingleItem(this.category, group, item)
                }
                if (this.isGift) {
                    if (!order.gifts) order.gifts = [];
                    order.gifts.push(oi);
                }
            }
        }


        this.updateUI();

        this.modalController.dismiss('ok');
    }

    round(value: number) {

        return MathUtils.round(value, 2);
    }

    getItemName(item: Item) {

        let name: string = item.name;

        if (this.isParent(item)) {
            name = item.title;
        }

        if (!name || name.length == 0) {
            name = item.name;
        }

        return name;
    }

}
