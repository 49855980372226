var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base/base-service';
import { ConfigService } from './config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { RouteTable } from '../route-table';
import { OrderService } from './order.service';
import { MenuService } from './menu.service';
import { OrderManager } from 'aigens-ng-core';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "./config.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ionic/angular";
import * as i5 from "./order.service";
import * as i6 from "./menu.service";
import * as i7 from "aigens-ng-core/dist/manager/order-manager";
import * as i8 from "@ngx-translate/core";
/**
 *  Use this singleton service to maintain state, pass parameters between navigation
 * */
var NavigationService = /** @class */ (function (_super) {
    __extends(NavigationService, _super);
    function NavigationService(configs, route, location, router, navController, orderService, menuService, orderManager, t) {
        var _this = _super.call(this) || this;
        _this.configs = configs;
        _this.route = route;
        _this.location = location;
        _this.router = router;
        _this.navController = navController;
        _this.orderService = orderService;
        _this.menuService = menuService;
        _this.orderManager = orderManager;
        _this.t = t;
        // TODO : keep a copy of cache in localstorage to handle refresh?
        _this.cache = {};
        _this.navigating = false;
        _this.poping = false;
        // private pageStack: string[] = [];
        _this.deactivateResult = new Subject();
        _this.backByLocation = false;
        _this.isAlertingDeactive = false;
        _this.rootPageUrl = '';
        return _this;
    }
    NavigationService.prototype.popPage = function () {
        this.poping = true;
        console.log('poping');
        // this.navController.pop().then(() => {
        //     console.log('pop done', this.poping);
        //     // this.poping = false;
        // });
        this.navController.setDirection('back', true, 'back');
        this.location.back();
    };
    NavigationService.prototype.popToParent = function (route) {
        var _this = this;
        this.poping = true;
        this.navController.pop().then(function () {
            console.log('pop done', _this.poping);
            // this.poping = false;
        });
        // this.navController.setDirection('back');
        // this.router.navigate(['../'], {relativeTo: route, replaceUrl: true}).then(() => {
        // });
    };
    NavigationService.prototype.getNavParams = function (key) {
        var navigation = this.router.getCurrentNavigation();
        if (!navigation || !navigation.extras || !navigation.extras.state || !navigation.extras.state[key]) {
            // check route params;
            /**
             *  Please make sure your component is never reused, ie. change from one item to another without destroy the original one.
             *  Otherwise use the code below:
             *  this.route.paramMap.pipe(
             *  switchMap((params: ParamMap) =>
             *      params.get('id')
             *  );
             */
            if (this.route.snapshot.paramMap.has(key)) {
                return this.route.snapshot.paramMap.get(key);
            }
            else {
                // check search params?
                var params = (new URL(window.location.href)).searchParams;
                if (params.get(key))
                    return params.get(key);
                else
                    return;
            }
        }
        return navigation.extras.state[key];
    };
    NavigationService.prototype.popToRoot = function () {
        this.poping = true;
        this.navController.setDirection('root', true, 'back');
        this.navController.navigateRoot(this.getRootPageUrl()).then(function () {
            // this.poping = false;
        });
    };
    NavigationService.prototype.getStore = function (storeId) {
        var _this = this;
        if (storeId && !this.orderService.store && this.orderManager.mode) {
            this.orderService.storeId = storeId;
            this.menuService.getStore(storeId, true, this.orderManager.mode, this.t.currentLang).subscribe(function (store) {
                if (store) {
                    _this.orderService.setStore_BehaviorSubject(store);
                }
            }, function (err) {
                console.log(err);
            });
        }
    };
    NavigationService.prototype.configRootUrl = function () {
        console.log('config root url in navigationService');
        // TODO: config the root page
        /**
         *  check params to generate root page url
         *  /store/{id}/spot/{tableNo}
         *  /store/{id}/mode/{mode}
         *  /court-store-list/{id}/mode/{mode}
         *  /court-store-list//{id}/spot/{tableNo} ?
         *  please add more cases here
         * */
        var temp = window.location.pathname;
        var rootUrl = '';
        var storeIndex = temp.indexOf('store/');
        var courtIndex = temp.indexOf('court-store-list/');
        var modeEnd = 0;
        // getStore
        var storeId, mode = '', spot;
        if (storeIndex !== -1) {
            rootUrl += 'store/';
            modeEnd = temp.indexOf('/', storeIndex + 6);
            if (modeEnd === -1) {
                modeEnd = temp.length;
            }
            rootUrl += temp.substring(storeIndex + 6, modeEnd);
            // getStore
            storeId = temp.substring(storeIndex + 6, modeEnd);
        }
        else if (courtIndex !== -1) {
            rootUrl += 'court-store-list/';
            modeEnd = temp.indexOf('/', courtIndex + 17);
            if (modeEnd === -1) {
                modeEnd = temp.length;
            }
            rootUrl += temp.substring(courtIndex + 17, modeEnd);
        }
        var spotIndex = temp.indexOf('spot/');
        if (spotIndex !== -1) {
            rootUrl += '/spot/';
            modeEnd = temp.indexOf('/', spotIndex + 5);
            if (modeEnd !== -1) {
                rootUrl += temp.substring(spotIndex + 5, modeEnd);
                spot = temp.substring(spotIndex + 5, modeEnd);
            }
            else {
                rootUrl += temp.substring(spotIndex + 5);
                spot = temp.substring(spotIndex + 5);
            }
        }
        var courtModeIndex = temp.indexOf('court/'); // court  Mode
        if (courtModeIndex !== -1) {
            rootUrl += 'court/';
            modeEnd = temp.indexOf('/', courtModeIndex + 6);
            rootUrl += temp.substring(courtModeIndex + 6, modeEnd !== -1 ? modeEnd : temp.length + 1); // court/123231   后面没 /
        }
        var historyIndex = temp.indexOf('history/'); // court  Mode
        if (historyIndex !== -1) {
            // direct history page
            rootUrl += temp;
        }
        var keyIndex = temp.indexOf('key/');
        if (keyIndex !== -1) {
            rootUrl += '/key/';
            var keyEnd = temp.indexOf('/', keyIndex + 4);
            if (keyEnd !== -1) {
                rootUrl += temp.substring(keyIndex + 4, keyEnd);
            }
            else {
                rootUrl += temp.substring(keyIndex + 4);
            }
        }
        var modeIndex = temp.indexOf('mode/');
        if (modeIndex !== -1) {
            rootUrl += '/mode/';
            modeEnd = temp.indexOf('/', modeIndex + 5);
            // let mode = '';
            if (modeEnd !== -1) {
                mode = temp.substring(modeIndex + 5, modeEnd);
            }
            else {
                mode = temp.substring(modeIndex + 5);
            }
            if (mode === 'takeaway' || mode === 'pickup' || mode === 'dinein' || mode === 'delivery' || mode === 'preorder' || mode === 'prekiosk') {
                rootUrl += mode;
            }
            else {
                console.warn('invalid mode ,do not set root url', mode);
                return;
            }
        }
        this.setRootPageUrl(rootUrl);
        // url init this.orderManager.mode start
        if (courtModeIndex === -1) {
            if (spot) {
                if (mode) {
                    this.orderManager.mode = mode;
                }
                else {
                    this.orderManager.mode = 'byod';
                }
            }
            else if (mode === 'preorder' || !mode) {
                this.orderManager.mode = 'preorder';
                this.orderService.preOrder = true;
            }
            else {
                this.orderManager.mode = mode;
            }
            this.orderManager.initMode = this.orderManager.mode;
            // url init this.orderManager.mode end
            if (storeId && this.orderManager.mode) {
                console.log('url get store(storeId, mode)', storeId, mode);
                this.getStore(storeId);
            }
        }
        console.log('set root Url in navigation service', rootUrl);
        if (!document.getElementById('baseHref').href || document.getElementById('baseHref').href === '/') {
            document.getElementById('baseHref').href = rootUrl;
        }
    };
    NavigationService.prototype.setRootPageUrl = function (url) {
        if (!url) {
            console.log('wrong input root url, reject', url);
            return;
        }
        this.rootPageUrl = url;
        this.configs.setLocal('rootPage', this.rootPageUrl);
        console.log('set root page ', url);
    };
    NavigationService.prototype.getRootPageUrl = function (ignoreCache) {
        if (ignoreCache === void 0) { ignoreCache = false; }
        if (ignoreCache && !this.rootPageUrl) {
            this.rootPageUrl = this.configs.getLocal('rootPage');
        }
        return this.rootPageUrl;
    };
    NavigationService.prototype.setNavigationParams = function (params) {
        this.cache = params;
    };
    NavigationService.prototype.getNavigationParams = function () {
        return this.cache;
    };
    NavigationService.prototype.push = function (page, params, extras) {
        if (params === void 0) { params = {}; }
        if (extras === void 0) { extras = {}; }
        var targetUrl = page.getPageUrl();
        return this.pushByUrl(targetUrl, params, extras);
    };
    NavigationService.prototype.pushByName = function (pageName, params, extras) {
        if (params === void 0) { params = {}; }
        if (extras === void 0) { extras = {}; }
        var targetUrl = RouteTable[pageName];
        if (!targetUrl) {
            console.error('cannot find url for page ', pageName);
        }
        else {
            console.log("page " + pageName + " at " + targetUrl);
        }
        return this.pushByUrl(targetUrl, params, extras);
    };
    NavigationService.prototype.pushByUrl = function (pageUrl, params, extras) {
        console.log('push by url', pageUrl);
        console.log('params', params);
        console.log('extras', extras);
        var temp = {};
        if (!this.getRootPageUrl() || this.getRootPageUrl() === '') {
            this.configRootUrl();
        }
        if (extras) {
            extras['state'] = params;
            // will this be true if extras.relativeTo = this.route.root?
            if (extras.relativeTo === this.route) {
                pageUrl = window.location.pathname + '/' + pageUrl;
            }
            else if (!extras.relativeTo) {
                pageUrl = this.getRootPageUrl() + '/' + pageUrl;
            }
            temp = extras;
        }
        else {
            pageUrl = this.getRootPageUrl() + '/' + pageUrl;
            temp['state'] = params;
        }
        // temp['skipLocationChange'] = false;
        // temp['replaceUrl'] = false;
        this.setNavigationParams(params);
        console.log('push to absolute url', pageUrl);
        this.backByLocation = extras && (extras.skipLocationChange);
        if (this.backByLocation) {
            window.history.pushState({}, 'Order.Place', window.location.href);
        }
        this.lastParams = params;
        temp['queryParamsHandling'] = 'merge';
        return this.navController.navigateForward([pageUrl], temp);
    };
    NavigationService.ngInjectableDef = i0.defineInjectable({ factory: function NavigationService_Factory() { return new NavigationService(i0.inject(i1.ConfigService), i0.inject(i2.ActivatedRoute), i0.inject(i3.Location), i0.inject(i2.Router), i0.inject(i4.NavController), i0.inject(i5.OrderService), i0.inject(i6.MenuService), i0.inject(i7.OrderManager), i0.inject(i8.TranslateService)); }, token: NavigationService, providedIn: "root" });
    return NavigationService;
}(BaseService));
export { NavigationService };
