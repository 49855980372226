/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./variation.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i4 from "@ionic/angular";
import * as i5 from "@angular/common";
import * as i6 from "./variation";
var styles_VariationDialog = [i0.styles];
var RenderType_VariationDialog = i1.ɵcrt({ encapsulation: 0, styles: styles_VariationDialog, data: {} });
export { RenderType_VariationDialog as RenderType_VariationDialog };
function View_VariationDialog_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "sold-out-box AQA-soldOut"], ["item-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("pages.item-grid.soldout")); _ck(_v, 2, 0, currVal_0); }); }
function View_VariationDialog_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "itemDesc-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getModifierString(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_VariationDialog_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "itemPrice-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.orderManager == null) ? null : ((_co.orderManager.store == null) ? null : _co.orderManager.store.currencySign)) === "\u20AC") ? "" : ((_co.orderManager == null) ? null : ((_co.orderManager.store == null) ? null : _co.orderManager.store.currencySign))); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), ((_co.settings.showDefaultPrice && _v.parent.context.$implicit.price0) ? _v.parent.context.$implicit.price0 : _v.parent.context.$implicit.price), ((("1." + ((_co.orderManager == null) ? null : _co.orderManager.getPrecision())) || ((0 + "-") + ((_co.orderManager == null) ? null : _co.orderManager.getPrecision()))) || 2))); var currVal_2 = ((((_co.orderManager == null) ? null : ((_co.orderManager.store == null) ? null : _co.orderManager.store.currencySign)) === "\u20AC") ? "\u20AC" : ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_VariationDialog_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ion-thumbnail", [["slot", "end"]], null, null, null, i3.View_IonThumbnail_0, i3.RenderType_IonThumbnail)), i1.ɵdid(1, 49152, null, 0, i4.IonThumbnail, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.getImage("default", 100), ""); _ck(_v, 2, 0, currVal_0); }); }
function View_VariationDialog_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "ion-item", [["class", "item-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClicked(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonItem_0, i3.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i4.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VariationDialog_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 7, "ion-label", [["class", "item-info"]], null, null, null, i3.View_IonLabel_0, i3.RenderType_IonLabel)), i1.ɵdid(5, 49152, null, 0, i4.IonLabel, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "h2", [["class", "itemName-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VariationDialog_3)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VariationDialog_4)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VariationDialog_5)), i1.ɵdid(13, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSoldout(_v.context.$implicit); _ck(_v, 3, 0, currVal_0); var currVal_2 = !((_co.settings == null) ? null : _co.settings.hideIgName); _ck(_v, 9, 0, currVal_2); var currVal_3 = (!((_co.settings == null) ? null : _co.settings.hideZeroItemPrice) || (_v.context.$implicit.price > 0)); _ck(_v, 11, 0, currVal_3); var currVal_4 = (_v.context.$implicit.images && _v.context.$implicit.images["default"]); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_1); }); }
export function View_VariationDialog_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { ionOutlet: 1 }), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "variation-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "list-header-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "list-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "ion-button", [["fill", "clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(8, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { fill: [0, "fill"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "ion-icon", [["color", "dark"], ["name", "md-close"], ["slot", "icon-only"]], null, null, null, i3.View_IonIcon_0, i3.RenderType_IonIcon)), i1.ɵdid(10, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "content"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "content-box"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "couponBS-wrapper animated fadeInRights"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VariationDialog_1)), i1.ɵdid(15, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 4, "div", [["class", "btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 3, "ion-button", [["class", "confirm-btn key-btn-box AQA-key-button"], ["expand", "block"]], [[2, "confirmed", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.okClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_IonButton_0, i3.RenderType_IonButton)), i1.ɵdid(19, 49152, null, 0, i4.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { expand: [0, "expand"] }, null), (_l()(), i1.ɵted(20, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "clear"; _ck(_v, 8, 0, currVal_1); var currVal_2 = "dark"; var currVal_3 = "md-close"; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.items; _ck(_v, 15, 0, currVal_4); var currVal_6 = "block"; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 6, 0, currVal_0); var currVal_5 = (_co.selectedIndex >= 0); _ck(_v, 18, 0, currVal_5); var currVal_7 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("pages.variation.confirm")); _ck(_v, 20, 0, currVal_7); }); }
export function View_VariationDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "page-variation", [], null, null, null, View_VariationDialog_0, RenderType_VariationDialog)), i1.ɵdid(1, 245760, null, 0, i6.VariationDialog, [i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VariationDialogNgFactory = i1.ɵccf("page-variation", i6.VariationDialog, View_VariationDialog_Host_0, {}, {}, []);
export { VariationDialogNgFactory as VariationDialogNgFactory };
