var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { Data, Item, ItemGroup, ModifierBuilder, OrderItem } from 'aigens-ng-core';
import { MobileBasePage } from '../../core/base/mobile-base-page';
import { NavParams } from '@ionic/angular';
var SideBarModifierDialog = /** @class */ (function (_super) {
    __extends(SideBarModifierDialog, _super);
    function SideBarModifierDialog(injector, navParams, cd) {
        var _this = _super.call(this, injector) || this;
        _this.injector = injector;
        _this.navParams = navParams;
        _this.cd = cd;
        _this.confirm = false;
        _this.mmap = {};
        _this.absentItemsId = [];
        _this.currentIndex = 0;
        _this.itemsCount = {};
        _this.selectItems = [];
        _this.orderItem = _this.navParams.get('orderitem');
        _this.store = _this.navParams.get('store');
        _this.group = _this.navParams.get('group');
        _this.item = _this.navParams.get('item');
        _this.itemMap = _this.navParams.get('itemMap');
        _this.item = _this.navParams.get('item');
        if (!_this.store) {
            _this.store = _this.orderManager.store;
        }
        _this.builder = new ModifierBuilder(_this.store, _this.orderItem);
        if (_this.group) {
            var items = _this.group.items.map(function (item) {
                _this.itemsCount[item.id] = item.quantity;
                var tmpItem = Data.toData(Item, __assign({}, item));
                var tmpMgroups = _this.itemMap[item.id].mgroups;
                tmpItem.mgroups = Data.toDataArray(ItemGroup, tmpMgroups.slice());
                return tmpItem;
            });
            _this.modifiableItems = items.filter(function (item) { return item.mgroups && item.mgroups.length > 0; });
        }
        if (_this.item) {
            _this.modifiableItems = [];
            _this.modifiableItems.push(_this.item);
        }
        _this.modifiableItems.forEach(function (item) {
            item.mgroups = item.mgroups.filter(function (mgroup) { return (mgroup.min === 0 && mgroup.items.length > 0) || mgroup.min > 0; });
            item.mgroups.forEach(function (mgroup) {
                // let tmp = mgroup.items.filter(item => item.absentId != null && item.absentId != "")
                // tmp.forEach((item)=>{
                //     this.absentItemsId.push(item.absentId);
                // })
                mgroup.items.forEach(function (mitem) {
                    if (mitem.absentId != null && mitem.absentId !== '') {
                        _this.absentItemsId.push(mitem.absentId);
                    }
                    if (mitem.mgroup && _this.has(item, mgroup, mitem)) {
                        _this.mmap[mitem.mgroup] = mitem;
                    }
                    if (mgroup.defaultId && mitem.id === mgroup.defaultId) {
                        _this.has(item, mgroup, mitem) || _this.itemClicked(item, mgroup, mitem, true);
                    }
                });
            });
        });
        _this.currentIndex = _this.navParams.get('showLastOne') ? _this.modifiableItems.length - 1 : 0;
        _this.currentItem = _this.modifiableItems[_this.currentIndex];
        // console.log('this.modifiableItems soldout', this.modifiableItems[0].mgroups[0].items[1], this.modifiableItems[0].mgroups[0].items[1].baseQty);
        // console.log('this.modifiableItems', this.modifiableItems[0].mgroups[0], this.modifiableItems[0].mgroups[0].items[0].baseQty);
        // console.log('this.modifiableItems', this.modifiableItems[0].mgroups[1], this.modifiableItems[0].mgroups[1].items[0].baseQty);
        // console.log('this.modifiableItems', this.modifiableItems[0].mgroups[2], this.modifiableItems[0].mgroups[2].items[0].baseQty);
        console.log('this.modifiableItems', _this.modifiableItems);
        // console.log('this.currentItem', this.currentItem, this.currentItem.mgroups[0].id);
        // to separate the mgroup.items when the mgroup.repeat is false according to the same string of mgroups[i].items[i].mgroup;
        _this.setMgroupItemsMap();
        console.log('this.mgroupItemsMap', _this.mgroupItemsMap);
        return _this;
        // console.log('数据', this.mgroupItemsMap[this.currentItem.mgroups[0].id]);
    }
    SideBarModifierDialog.prototype.ngOnInit = function () {
        this.showSelectedItems();
        console.log('ngOnInit side bar VariationPage');
    };
    SideBarModifierDialog.prototype.setMgroupItemsMap = function () {
        var _this = this;
        var obj = {};
        this.modifiableItems.forEach(function (item) {
            item.mgroups && item.mgroups.forEach(function (mgroup) {
                if (_this.classify(mgroup.items, 'mgroup').length > 0)
                    obj[mgroup.id] = _this.classify(mgroup.items, 'mgroup');
            });
        });
        console.log('obj', obj);
        this.mgroupItemsMap = obj;
    };
    SideBarModifierDialog.prototype.classify = function (arr, key) {
        var _this = this;
        // 根据 对象数据的某一项 key 值 来新建一个对象
        var obj = {};
        arr.map(function (item) {
            if (item[key])
                obj[item[key]] = null;
            else
                obj['noMgroupStr'] = null;
        });
        var valuesArr = Object.keys(obj); // 包含 key 所有值的数组
        console.log('valuesArr', valuesArr);
        // 根据 key 的值 把对象数组 分类
        var res = valuesArr.map(function (val) {
            return arr.filter(function (item) {
                var ok = true;
                if (item[key]) {
                    ok = val === item[key];
                }
                if (ok) {
                    // check itme avaiable time
                    if (!_this.menuManager.isAvailabeByStartEnd(item, new Date().getTime())) {
                        console.log('item time not available', item.name);
                        return false;
                    }
                }
                return ok;
            });
        });
        return res;
    };
    SideBarModifierDialog.prototype.getSelectRules = function (igroup) {
        if (!igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return '';
        }
        var min = Number(igroup.min);
        var max = Number(igroup.max);
        if (min === max && min === 0) {
            return '';
        }
        // 請選擇{{count}}項
        if (min === max) {
            return this.t.instant('pages.item-select.select-items', { count: min });
        }
        // 加配項目
        if (min === 0 && max === 99) {
            return this.t.instant('pages.item-select.optional');
        }
        // 請選最多max項
        if (min === 0 && max !== 99) {
            return this.t.instant('pages.item-select.chooseAtMost', { max: max });
        }
        // 請選至少{{min}}項
        if (min !== 0 && max === 99) {
            return this.t.instant('pages.item-select.chooseUp', { min: min });
        }
        // 請選{{min}} - {{max}}項
        if (min < max && max !== 99) {
            return this.t.instant('pages.item-select.chooseTo', { min: min, max: max });
        }
        return '';
    };
    SideBarModifierDialog.prototype.dismissClicked = function (confirem) {
        if (confirem === void 0) { confirem = false; }
        this.modalController.dismiss(confirem);
    };
    SideBarModifierDialog.prototype.isGroupSelected = function (item, igroup) {
        if (!item || !igroup || isNaN(igroup.max) || isNaN(igroup.min)) {
            return false;
        }
        var min = Number(igroup.min);
        var max = Number(igroup.max);
        var items = igroup.items || [];
        var isSelect = false;
        var quantities = 0;
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var tmpItem = items_1[_i];
            quantities += this.builder.getQuantity(item, igroup, tmpItem);
        }
        if (min > 0) {
            isSelect = quantities >= igroup.min;
        }
        else if (quantities > 0) { /// min = 0
            isSelect = true;
        }
        return isSelect;
    };
    SideBarModifierDialog.prototype.itemClicked = function (item, group, mItem, isPreselect) {
        if (isPreselect === void 0) { isPreselect = false; }
        var max = group.max;
        var contain = this.has(item, group, mItem);
        if (mItem.baseQty > 0) {
            var count = this.builder.getQuantity(item, group, mItem);
            if (count < mItem.max) {
                this.builder.plus(item, group, mItem);
            }
        }
        else if (group.repeat) {
            if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                this.builder.plus(item, group, mItem);
            }
            else {
                // when group.repeat==true&&group.max==1
                if (max === 1)
                    this.minusClicked(item, group, mItem);
            }
        }
        else {
            if (max === 1) {
                this.builder.clearForItem(item, group);
                if (!contain) {
                    this.builder.setMod(item, group, mItem, 1);
                }
            }
            else if (max > 1) {
                var q = void 0;
                if (contain) {
                    q = 0;
                    if (mItem.mgroup) {
                        this.mmap[mItem.mgroup] = null;
                    }
                }
                else {
                    if (this.canAdd(item, group, mItem.mgroup ? mItem : null)) {
                        q = 1;
                    }
                    else {
                        q = 0;
                    }
                }
                if (mItem.mgroup && q === 1) {
                    this.clearSameMGroup(item, group, mItem);
                }
                this.builder.setMod(item, group, mItem, q);
            }
        }
        if (!isPreselect && group.min > 0 && this.isItemCompleted(item) && this.currentIndex + 1 < this.modifiableItems.length) {
            this.selectItem(this.modifiableItems[this.currentIndex + 1], this.currentIndex + 1);
        }
        if (!isPreselect) {
            this.cd.detectChanges();
        }
        this.showSelectedItems(); // update
    };
    SideBarModifierDialog.prototype.clearSameMGroup = function (item, group, mItem) {
        var i = this.mmap[mItem.mgroup];
        if (i) {
            this.builder.setMod(item, group, i, 0);
        }
        this.mmap[mItem.mgroup] = mItem;
    };
    SideBarModifierDialog.prototype.confirmClicked = function () {
        if (!this.isAllCompleted(this.modifiableItems)) {
            this.showAlert('', 'Please complete all requried fields');
            return;
        }
        if (!this.isAllCompleted(this.modifiableItems)) {
            console.log('modifier not completed, cannot confirm');
        }
        var orderManager = this.multOrderManager.enableMutlOrder ? this.multOrderManager.getOrderManager(this.store.id) : this.orderManager;
        if (OrderItem.isSet(this.orderItem) || this.orderItem.quantity === 1) {
            var oi = this.builder.build();
            orderManager.refreshOrderItem(oi);
        }
        else {
            var duplicated = orderManager.duplicateOrderItem(this.orderItem);
            duplicated.quantity--;
            var oi = this.builder.build();
            oi.quantity = 1;
            orderManager.refreshOrderItem(oi);
        }
        this.dismissClicked(true);
    };
    SideBarModifierDialog.prototype.has = function (item, group, mItem) {
        // console.log(mItem.name + ":" + this.builder.getQuantity(item, group, mItem));
        return this.builder.getQuantity(item, group, mItem) > 0;
    };
    SideBarModifierDialog.prototype.showSelectedItems = function () {
        var _this = this;
        var selectItems = [];
        this.currentItem && this.currentItem.mgroups.map(function (mgroup, midx) {
            selectItems[midx] = [];
            mgroup.items.map(function (item) {
                var quantity = _this.builder.getQuantity(_this.currentItem, mgroup, item);
                if (quantity > 1) {
                    selectItems[midx].push(item.name + " x " + quantity);
                }
                else if (quantity === 1) {
                    selectItems[midx].push("" + item.name);
                }
            });
        });
        this.selectItems = selectItems;
    };
    SideBarModifierDialog.prototype.canAdd = function (item, group, mItem) {
        var _this = this;
        var max = group.max;
        var quantities = 0; // this.builder.getSelectedModifierCount(item);
        for (var _i = 0, _a = group.items; _i < _a.length; _i++) {
            var tmpItem = _a[_i];
            quantities += this.builder.getQuantity(item, group, tmpItem);
        }
        this.mmap = {};
        group.items.forEach(function (mitem) {
            if (mitem.mgroup && _this.has(item, group, mitem)) {
                _this.mmap[mitem.mgroup] = mitem;
            }
        });
        if (max > quantities && mItem && this.mmap[mItem.mgroup] && mItem.id !== this.mmap[mItem.mgroup].id) {
            return true;
        }
        return max > quantities;
    };
    SideBarModifierDialog.prototype.minusClicked = function (item, group, mItem) {
        var count = this.builder.getQuantity(item, group, mItem);
        if (count > mItem.min) {
            this.builder.minus(item, group, mItem);
        }
        this.showSelectedItems();
        this.cd.detectChanges();
    };
    SideBarModifierDialog.prototype.selectItem = function (item, index) {
        this.currentItem = item;
        this.currentIndex = Number(index);
        this.showSelectedItems();
    };
    SideBarModifierDialog.prototype.mgroupSegmentChanged = function (e) {
        console.log('mgroupSegmentChanged event', e);
        this.selectItem(this.modifiableItems[e.detail.value], e.detail.value);
    };
    SideBarModifierDialog.prototype.isItemCompleted = function (item) {
        var completed = true;
        var mgroups = this.itemMap[item.id].mgroups;
        if (mgroups) {
            for (var i = 0; i < mgroups.length; i++) {
                var quantities = 0;
                for (var _i = 0, _a = mgroups[i].items; _i < _a.length; _i++) {
                    var tmpItem = _a[_i];
                    quantities += this.builder.getQuantity(item, mgroups[i], tmpItem);
                }
                if (mgroups[i].min > 0) {
                    completed = quantities >= mgroups[i].min;
                }
                else {
                    completed = true;
                }
                if (!completed) {
                    break;
                }
            }
        }
        return completed;
    };
    SideBarModifierDialog.prototype.isAllCompleted = function (items) {
        // return item.mgroups
        // .filter(mgroup => mgroup.min > 0)
        // .some((mgroup)=>{
        //     return mgroup.items.length >= mgroup.min;
        // });
        var completed = true;
        for (var _i = 0, items_2 = items; _i < items_2.length; _i++) {
            var item = items_2[_i];
            completed = this.isItemCompleted(item);
            if (!completed) {
                break;
            }
        }
        return completed;
    };
    return SideBarModifierDialog;
}(MobileBasePage));
export { SideBarModifierDialog };
