import { createTranslateLoader } from './utilities/createTranslateLoader';
// pipe
import { GetDateStringLabelPipe } from './pipes/getDateStringLabelPipe';
import { ComboIdFilter } from './pipes/comboId-filter.pipes';
import { TimeStringLabelPipe } from './pipes/timeStringLabelPipe';
import { CustomFilterPipe } from './pipes/custom-filter.pipe';
import { ItemPricePipe } from './pipes/item-price.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
var THIRDMODULES = [];
// #endregion
// #region your componets & directives
var COMPONENTS = [];
var DIRECTIVES = [];
var PIPES = [GetDateStringLabelPipe, ItemPricePipe, TimeStringLabelPipe, CustomFilterPipe, SafeHtmlPipe, ComboIdFilter];
var ɵ0 = createTranslateLoader;
// #endregion
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
