var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { BaseService } from '../base/base-service';
import { FireStoreConfig } from "../../config";
var FirestoreService = /** @class */ (function (_super) {
    __extends(FirestoreService, _super);
    function FirestoreService() {
        var _this = _super.call(this) || this;
        _this.app = firebase.initializeApp(FireStoreConfig.getConfig(), 'store');
        _this._firestore = _this.app.firestore();
        return _this;
    }
    Object.defineProperty(FirestoreService.prototype, "firestore", {
        get: function () {
            return this._firestore;
        },
        enumerable: true,
        configurable: true
    });
    FirestoreService.prototype.switchContextAuth = function (store, callback) {
        var statusUrl = store['statusUrl'];
        if (!statusUrl)
            return;
        this.app = this.getFirebaseApp(statusUrl);
        if (this.authUnsub) {
            this.authUnsub();
        }
        this.authWithToken(this.app, null, callback);
    };
    FirestoreService.prototype.authMember = function (member) {
        var token;
        if (member.extra) {
            token = member.extra.firebaseToken;
        }
        var statusUrl = member.statusUrl;
        if (!token || !statusUrl)
            return;
        this.auth(statusUrl, token, function (user) {
            console.log("auth success", user);
        });
    };
    FirestoreService.prototype.auth = function (statusUrl, token, callback) {
        this.app = this.getFirebaseApp(statusUrl);
        if (this.authUnsub) {
            this.authUnsub();
        }
        this.authWithToken(this.app, token, callback);
    };
    FirestoreService.prototype.switchContextAnon = function (content, callback) {
        var statusUrl = content['statusUrl'];
        if (!statusUrl)
            return;
        this.app = this.getFirebaseApp(statusUrl);
        if (this.authUnsub) {
            this.authUnsub();
        }
        this.authWithAnon(this.app, callback);
    };
    FirestoreService.prototype.switchContext = function (content) {
        var statusUrl = content['statusUrl'];
        if (!statusUrl)
            return;
        this.app = this.getFirebaseApp(statusUrl);
    };
    FirestoreService.prototype.getFirebaseApp = function (statusUrl) {
        console.log("statusUrl", statusUrl);
        var segs = statusUrl.split('/');
        var projectId = this.findParam(segs, "projects");
        var apiKey;
        var url = new URL(statusUrl);
        if (!!url.searchParams) {
            var urlParams = url.searchParams;
            apiKey = urlParams.get("apiKey");
        }
        else {
            if (statusUrl.indexOf('apiKey=') != -1) {
                var tempKey = statusUrl.split('?');
                var k = tempKey.find(function (key) { return key.indexOf('apiKey=') != -1; });
                apiKey = k.substring('apiKey='.length);
            }
        }
        var fbConfig = {
            projectId: projectId
        };
        if (apiKey) {
            fbConfig['apiKey'] = apiKey;
        }
        var app = null;
        for (var _i = 0, _a = firebase.apps; _i < _a.length; _i++) {
            var a = _a[_i];
            if (a.name == projectId) {
                app = a;
            }
        }
        if (!app) {
            app = firebase.initializeApp(fbConfig, projectId);
        }
        else {
            console.log("reusing firebase");
        }
        return app;
    };
    FirestoreService.prototype.getFirestore = function () {
        return this.app.firestore();
    };
    FirestoreService.prototype.authWithToken = function (app, token, callback) {
        this.authUnsub = app.auth().onAuthStateChanged(function (user) {
            if (user) {
                console.log("user logged in", user);
                callback(user);
            }
            else {
                if (token) {
                    console.log("signing in with token", token);
                    app.auth().signInWithCustomToken(token).catch(function (error) {
                        // Handle Errors here.
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log("Auth Error", errorCode, errorMessage);
                        callback(null);
                    });
                }
                else {
                    console.log("must signin to firestore first");
                    callback(null);
                }
            }
        });
    };
    FirestoreService.prototype.authWithAnon = function (app, callback) {
        this.authUnsub = app.auth().onAuthStateChanged(function (user) {
            if (user) {
                console.log("user logged in", user);
                callback(user);
            }
            else {
                console.log("signing in with anon");
                app.auth().signInAnonymously().catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log("Auth Error", errorCode, errorMessage);
                    callback(null);
                });
            }
        });
    };
    FirestoreService.prototype.signOut = function () {
        if (this.authUnsub) {
            this.authUnsub();
        }
        if (this.app) {
            this.app.auth().signOut().then(function () {
                // Sign-out successful.
                console.log("signed off successfully");
            }).catch(function (error) {
                // An error happened.
                console.log("signed off error", error);
            });
        }
    };
    FirestoreService.prototype.getFirestoreDoc = function (statusUrl) {
        var db = this.getFirestore();
        var segs = statusUrl.split('/');
        var kind = this.findParam(segs, "documents");
        var contentId = this.findParam(segs, kind);
        return db.collection(kind).doc(contentId);
    };
    FirestoreService.prototype.findParam = function (paths, name) {
        for (var i = 0; i < paths.length; i++) {
            if (paths[i] == name) {
                return paths[i + 1];
            }
        }
        return null;
    };
    FirestoreService.prototype.listenOrderStatus = function (orderId, callBack) {
        this.getFirestore().collection("Order").doc(orderId).onSnapshot(function (doc) {
            console.log("Current data: ", doc.data());
            callBack(doc.data());
        });
    };
    return FirestoreService;
}(BaseService));
export { FirestoreService };
