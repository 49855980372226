
import { BaseService } from '../base/base-service';

import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Injectable({ providedIn: 'root' })
export class LoadingService extends BaseService {

    lastLoadingStatus = false;
    private existingLoading = false; // 唯一指示当前是否有loading的变量(大坑：由于继承和派生的原因 basepage中的变量不是唯一的)
    private loader: HTMLIonLoadingElement;
    constructor(protected loadingController: LoadingController) {
        super();
    }

    async loading(show, message: string = null, refresher) {
        console.log('loading, show', show);
        this.lastLoadingStatus = show;
        // console.trace();
        if (show) {

            // if (this.refresher || this.loader) {
            // not use 异步赋值的变量来判断是否存在 loading
            if (refresher || this.existingLoading) {
                return;
            }

            /*
            this.loader = this.loadingController.create({
                //content: 'Please wait...'
            });*/
            this.setExistingLoading(true);
            this.loader = await this.makeLoader(message);
            if (this.lastLoadingStatus) {
                this.loader.present();
            }
            console.log('loading showed');

        } else {

            if (this.loader) {
                // this.loader.dismiss();
                await this.loader.dismiss();
                this.loader = null;
                this.setExistingLoading(false);
            }

            if (refresher) {
                refresher.complete();
                refresher = null;
            }
            console.log('loading dismissed');


        }
    }

    private setExistingLoading(bool: boolean) {
        this.existingLoading = bool;
    }

    async makeLoader(message: string = null): Promise<HTMLIonLoadingElement> {
        if (message == null || message.length === 0) {
            return await this.loadingController.create({});
        } else {
            return await this.loadingController.create({
                message: message
            });
        }
    }

}
