/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "./core/services/config.service";
import * as i7 from "aigens-ng-core/dist/manager/menu-manager";
import * as i8 from "./core/services/navigation.service";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./core/services/session.service";
import * as i11 from "./core/services/check-version.service";
import * as i12 from "./core/services/browser.service";
import * as i13 from "./core/services/sdk-config-service";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { routerOutlet: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 5, "ion-app", [], null, null, null, i1.View_IonApp_0, i1.RenderType_IonApp)), i0.ɵdid(2, 49152, null, 0, i2.IonApp, [i0.ChangeDetectorRef, i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(3, 16777216, [["outlet", 1]], 0, 3, "ion-router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(5, { "wechat": 0, "fb": 1 }), i0.ɵdid(6, 212992, [[1, 4]], 0, i2.IonRouterOutlet, [i4.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], [8, null], i2.Config, i2.NavController, i3.Location, i0.ElementRef, i4.Router, i0.NgZone, i4.ActivatedRoute, [3, i2.IonRouterOutlet]], { swipeGesture: [0, "swipeGesture"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.isWechat, _co.isFacebook); _ck(_v, 4, 0, currVal_0); var currVal_1 = false; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i5.AppComponent, [i6.ConfigService, i2.NavController, i7.MenuManager, i8.NavigationService, i4.ActivatedRoute, i2.AlertController, i9.TranslateService, i2.MenuController, i10.SessionService, i4.Router, i2.Platform, i2.ModalController, i11.VersionCheckService, i2.ActionSheetController, i12.BrowserService, i13.SdkConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
