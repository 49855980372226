import {Data, Store} from 'aigens-ng-core';
import 'rxjs/Rx';
import {AQuery} from '../base/aquery';
import {BaseService} from '../base/base-service';
import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class StoreService extends BaseService {

    aq: AQuery;
    storeCountryCodesArr: any;

    constructor(private http: HttpClient, public configs: ConfigService) {
        super();
        this.aq = new AQuery(http, configs);
    }

    // use in store list
    searchStores(groupId: any, q, latitude: number = null, longitude: number = null, regionCodes: string[] = null, radius: number = null, limit: number = null) {

        const url = '/api/v1/store/store.json?groupId=' + groupId;

        const params = {};
        params['q'] = q;

        if (latitude != null && longitude != null) {
            params['latitude'] = latitude;
            params['longitude'] = longitude;
        }

        if (radius != null) {
            params['radius'] = radius;
        }

        if (limit != null) {
            params['limit'] = limit;
        }

        if (regionCodes != null && regionCodes.length > 0) {
            params['region'] = regionCodes.join(',');
        }

        const aq = this.aq;
        aq.url = url;
        aq.params = params;

        return aq.getJson().pipe(map(jo => Data.toDataArray(Store, jo['data'])));

    }


    verifyTableCode(code: string, storeId: number): Observable<any> {
        const url = '/api/v1/menu/spot.json?storeId=' + storeId + '&code=' + code;

        this.aq.url = url;
        this.aq.method = 'get';


        return this.aq.getJson().pipe(map((jo) => {
            return jo['data'];
        }));
    }

    getStoreRegionsByBandId(brandId) {
        let url = '/api/v1/store/directory.json?brandId=' + brandId;

        console.log('getStoreRegionsByBandId', url);

        const aq = this.aq;
        aq.url = url;

        return aq.getJson().pipe(map(jo => {
            console.log('getStoreRegionsByBandId jo', jo);
            return jo['data'];
        }));
    }

    setStoreCountryCodesArr(arr) {
        this.storeCountryCodesArr = null;
        if (arr && arr.length > 0)
            this.storeCountryCodesArr = arr;
    }
}
